import React, { useEffect, useRef, useState } from "react";
import logoAladdinWhite from "../../Assets/Images/logo-aladdin-white.png";
import { MakeAxiosRequest } from "../../utils/handler";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useDispatch } from "react-redux";
import { login } from "../../features/authSlice";
import { isEmpty } from "lodash";
import InputMask from 'react-input-mask';
import { phoneRegex } from "../../Constants";

function Login() {
  

  const [ishomepage, setIshomepage] = useState(true);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isContinueDisabled, setContinueDisabled] = useState(false);
  const [otpvalues, setOtpvalues] = useState({
    otp_uuid: "",
    otp: "",
    phone_number: "",
  });
  const [OTP, setOTP] = useState("");
  const [otperror, setOtperror] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const accesstoken = localStorage.getItem('accesstoken');
    if(!isEmpty(accesstoken)) {
      navigate("/dashboard");
    }
  }, [])
  
  useEffect(() => {
    setOtpvalues({...otpvalues,otp: OTP});
    setOtperror('');
    if(OTP.length==4)
        setButtonDisabled(false);
    else
        setButtonDisabled(true);
  }, [OTP]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  
  const sentOtp = async (formData) => {
    setContinueDisabled(true);

    const res = await MakeAxiosRequest("post", formData, "/generateotp");
    setContinueDisabled(false);
    // console.log(res);
    if (res.status == 1) {
      setOTP('');

      setIshomepage(false);
      reset();
      setOtpvalues({
        ...otpvalues,
        phone_number: formData.phone_number,
        otp_uuid: res.data.otp_uuid,
      });
      // alert(res.data.otp)
    } else {
      setError('phone_number', { type: 'custom', message: res.message });
      // alert(res.message);
    }
  };

  const verifyOtp = async () => {
    // setOtpvalues({...otpvalues,otp: OTP});
    const res = await MakeAxiosRequest("post", otpvalues, "/validateotp");
     console.log(res.data);
    if (res.status == 1) {
      // localStorage.setItem('accesstoken', res.data.accesstoken);
      // localStorage.setItem('userName', res.data.name);
      // localStorage.setItem('profileImage', res.data.profileimg);
      // localStorage.setItem('user', JSON.stringify(res.data));
      // Dispatch the login action with user details
      dispatch(login(res.data));
      navigate("/dashboard");
      // console.log(res.data.accesstoken);
    } else {
      setOtperror(res.message);
    }
  };

  const resendOtp = async () => {
    const res = await MakeAxiosRequest("post", {phone_number:otpvalues.phone_number}, "/generateotp");
    if (res.status == 1) {
      setOtpvalues({
        ...otpvalues,
        otp_uuid: res.data.otp_uuid,
      });
      // alert(res.data.otp)
    } else {
      alert(res.message);
    }
  };

  const renderButton = (buttonProps) => {
    return (
      <button {...buttonProps} className="btn resend-btn" type="button">
        {buttonProps.remainingTime !== 0 ? `Resend OTP in ${buttonProps.remainingTime} sec` : "Resend OTP"}
      </button>
    );
  };
  const renderTime = () => React.Fragment;

  // const renderButton = (buttonProps) => {
  //   return <button {...buttonProps}>Resend</button>;
  // };
  // const renderTime = (remainingTime) => {
  //   return remainingTime !== 0 && (
  //   <span>{remainingTime} seconds remaining</span>
  //   );
  // };

  return (
    <>
      <section className="login-pg">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-lg-6">
              <div className="login-lft-sec">
                <img src={logoAladdinWhite} className="" alt="" />
                <div className="login-img-txt">
                  <h3>
                    Get notified by local <br />
                    customers for your business
                  </h3>
                  <p>
                    Harness the power of local recognition and customer trust for business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-10 col-xl-7 mx-auto">
                    <div className="login-form">
                      {ishomepage ? (
                        <>
                          <div className="top-login"></div>
                          <div className="mt-4">
                            <h1>Welcome Back</h1>
                            <p>
                              Enter your mobile number and we will send you a
                              verification code
                            </p>
                            <form
                              className="my-4"
                              onSubmit={handleSubmit(sentOtp)}
                            >
                              <div className="mb-3">
                                <label
                                  htmlFor="phoneNumber"
                                  className="form-label"
                                >
                                  Enter your mobile number
                                </label>
                                {/* <InputMask  */}
                                {/* mask="9999999999" */}

                                <input
                                  type="text"
                                  className="form-control"  
                                  maxLength="10"  // Limit the input to 10 characters
                                  {...register("phone_number", {
                                    required: "Phone number is required",
                                    pattern: {
                                      value:phoneRegex,
                                      message:"Please enter a valid phone number",
                                    },
                                  })}
                                />

                                {errors.phone_number && (
                                  <span className="error">
                                    {errors.phone_number.message}
                                  </span>
                                )}
                              </div>
                              <button 
                                  type="submit" 
                                  className="btn btn-primary"
                                  disabled={isContinueDisabled}
                              >
                                Continue
                              </button>
                            </form>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="top-login">
                            <a onClick={() => setIshomepage(true)} href="#">
                              <i className="fa-solid fa-arrow-left-long me-2"></i>
                              Back
                            </a>
                          </div>
                          <div className="mt-4">
                            <h1>OTP Verification</h1>
                            <p>Enter the OTP received</p>
                            <form
                              className="my-4"
                              onSubmit={handleSubmit(verifyOtp)}
                            >
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleInputEmail1"
                                  className="form-label"
                                >
                                  OTP Code
                                </label>
                                <OTPInput
                                  className="otp-input"
                                  inputClassName="form-control"
                                  value={OTP}
                                  onChange={setOTP}
                                  secure={true}
                                  autoFocus
                                  OTPLength={4}
                                  otpType="number"
                                  disabled={false}
                                />
                                {otperror && (
                                  <span className="error">
                                    {otperror}
                                  </span>
                                )}
                              </div>
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary mb-3"
                                  disabled={isButtonDisabled}
                                >
                                  Verify
                                </button>
                                <ResendOTP onResendClick={resendOtp} className="d-flex justify-content-center link" renderButton={renderButton} renderTime={renderTime} />

                                {/* <a className="link" onClick={resendOtp}>Resend OTP</a> */}
                              </div>
                            </form>
                          </div>
                        </>
                      )}

                      <div className="copy-rt-sec">
                        <p>© WhiteShark Enterprises {(new Date().getFullYear())}. All rights reserved</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Login;
